<template>
 <div>
  <RelationMain v-if="errorRelation">
   <sdCards title="">
    <a-result status="warning" style="padding: 0"
              title="Er is een fout opgetreden bij het ophalen van de gegevens.">
     <template #extra>
      <Button class="ant-btn ant-btn-primary" size="large" type="primary" @click="$router.replace('/relation')">
       Naar relations
      </Button>
     </template>
    </a-result>
   </sdCards>
  </RelationMain>
  <RelationMain v-if="!errorRelation">
   <Upload>
    <div class="user-info-form">
     <BasicFormWrapper class="mb-25">
      <sdCards title="Persoonsgegevens">
       <a-form :model="form" :rules="rules1" layout="vertical" @finish="UpdateRelation1">
        <a-row :gutter="30">
         <a-col :sm="4" :xs="24" class="mb-25">
          <a-form-item label="Geslacht" name="gender" v-bind="validateInfos.geslacht">
           <a-select v-model:value="form.gender" :allowClear="true" :disabled="(disableInputs)"
                     class="sDash_fullwidth-select"
                     name="gender"
                     size="large" @change="handleChange('GENDER',$event)">
            <a-select-option v-for="(item,index) in genderOptions" :key="index" :value="item.value">
             {{ item.key }}
            </a-select-option>
           </a-select>
          </a-form-item>
         </a-col>
         <a-col :sm="7" :xs="24" class="mb-25">
          <a-form-item label="Voornaam" name="firstName" v-bind="validateInfos.firstName">
           <a-input v-model:value="form.firstName" :disabled="disableInputs"
                    placeholder=""/>
          </a-form-item>
         </a-col>
         <a-col :sm="4" :xs="24" class="mb-25">
          <a-form-item label="Tussenvoegsel" name="middleName">
           <a-input v-model:value="form.middleName" :disabled="disableInputs"
                    placeholder=""/>
          </a-form-item>
         </a-col>
         <a-col :sm="9" :xs="24" class="mb-25">
          <a-form-item label="Achternaam" name="lastName" v-bind="validateInfos.lastName">
           <a-input v-model:value="form.lastName" :disabled="disableInputs"
                    placeholder=""/>
          </a-form-item>
         </a-col>
        </a-row>
        <a-row :gutter="30">
         <a-col :sm="8" :xs="24" class="mb-25">
          <a-form-item label="Email" name="email" v-bind="validateInfos.email">
           <a-input v-model:value="form.email" :disabled="disableInputs"
                    placeholder=""/>
          </a-form-item>
         </a-col>
         <a-col :sm="8" :xs="24" class="mb-25">
          <a-form-item label="Telefoonnummer" name="phoneNumber" v-bind="validateInfos.phoneNumber">
           <a-input v-model:value="form.phoneNumber" :disabled="disableInputs"
                    onkeydown="if(event.key==='.'||event.key===','){event.preventDefault();}"
                    onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                    placeholder=""
                    type="number"/>
          </a-form-item>
         </a-col>
         <a-col v-if="enableBirthday" :sm="8" :xs="24" class="mb-25">
          <a-form-item label="Geboortedatum" name="birthDate" v-bind="validateInfos.birthDate">
           <DatePickerWrapper>
            <a-date-picker v-model:value="form.birthDate"
                           :defaultPickerValue="moment().subtract(minAge > 0 ? minAge : 18, 'years')"
                           :disabled="disableInputs"
                           :disabled-date="disabledDate" :format="dateFormat"
                           :valueFormat="valueFormat"
                           class="w-100"/>
           </DatePickerWrapper>
          </a-form-item>
         </a-col>
         <a-col v-if="showIBAN" :sm="8" :xs="24" class="mb-25">
          <a-form-item label="IBAN" name="bankAccountNumber" v-bind="validateInfos.bankAccountNumber">
           <a-input v-model:value="form.bankAccountNumber" :disabled="disableInputs"
                    placeholder=""/>
          </a-form-item>
         </a-col>
         <a-col v-if="showIBAN" :sm="8" :xs="24" class="mb-25">
          <a-form-item label="Tenaamstelling" name="debtorName" v-bind="validateInfos.debtorName">
           <a-input v-model:value="form.debtorName" :disabled="disableInputs"
                    placeholder=""/>
          </a-form-item>
         </a-col>
        </a-row>
        <a-row v-if="showCardSaveButton">
         <a-col :sm="12" :xs="24">
          <div class="sDash_form-action">
           <sdButton :disabled="disableInputs" class="btn-signin"
                     type="primary"> Opslaan
           </sdButton>
          </div>
         </a-col>
        </a-row>
       </a-form>
      </sdCards>
     </BasicFormWrapper>
    </div>
   </Upload>
   <div class="user-info-form">
    <a-skeleton v-if="loadingRelation" active/>
    <BasicFormWrapper v-else class="mb-25">
     <sdCards title="Bedrijfsgegevens">
      <a-form ref="companyInfoForm" :model="form" layout="vertical" @finish="UpdateRelation2">
       <a-row :gutter="30">
        <a-col :sm="24" :xs="24" class="mb-25">
         <a-radio-group v-model:value="form.type"
                        :disabled="disableIsBusiness||type === 'EDIT' || type === 'ORDEREDIT'||disableInputs"
                        @change="handleChange('CUSTOMERTYPE',$event)">
          <a-radio-button v-for="item in customerTypeOptions" :key="item.value" :value="item.value">
           {{ item.key }}
          </a-radio-button>
         </a-radio-group>
        </a-col>
        <a-col v-if="form.type === 'BUSINESS'" :sm="24" :xs="24" class="mb-25">
         <a-form-item label="Zoek in het KVK register" name="companyName">
          <AutoCompleteWrapper class="w-100">
           <AutoCompleteStyled v-model:value="form.companyName" :dropdownMatchSelectWidth="true"
                               class="w-100" @search="onSearchCoc">
            <template v-if="cocOptions && cocOptions.length > 0" #options>
             <a-select-option v-for="item in cocOptions" :key="item.companyCocNumber"
                              :disabled="disableInputs"
                              :value="item.companyName" @click="onCocSelect(item)">
              {{ item.companyName }} - {{ item.companyCocNumber }}
             </a-select-option>
            </template>
            <template v-else #options>
             <a-select-option key="Data not found!"> Geen data gevonden!</a-select-option>
            </template>
            <slot></slot>
           </AutoCompleteStyled>
          </AutoCompleteWrapper>
         </a-form-item>
        </a-col>
       </a-row>
       <a-row v-if="form.type === 'BUSINESS'" :gutter="30">
        <a-col :sm="12" :xs="24" class="mb-25">
         <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Bedrijfsnaam"
                      name="companyName">
          <a-input v-model:value="form.companyName" :disabled="disableInputs"
                   placeholder=""/>
         </a-form-item>
        </a-col>
        <a-col :sm="12" :xs="24" class="mb-25">
         <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="KVK nummer"
                      name="companyCocNumber">
          <a-input v-model:value="form.companyCocNumber" :disabled="disableInputs"
                   onkeydown="if(event.key==='.'||event.key===','){event.preventDefault();}"
                   onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                   placeholder=""
                   type="text"/>
         </a-form-item>
        </a-col>
        <a-col :sm="12" :xs="24" class="mb-25">
         <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Bedrijfsvorm"
                      name="companyLegalForm">
          <a-select v-model:value="form.companyLegalForm" :allowClear="true" :disabled="disableInputs"
                    class="sDash_fullwidth-select"
                    name="companyLegalForm">
           <a-select-option v-for="(item,index) in companyLegalFormOptions" :key="index" :value="item.key">
            {{ item.value }}
           </a-select-option>
          </a-select>
         </a-form-item>
        </a-col>
       </a-row>
       <a-row v-if="(type === 'EDIT' || type === 'ORDEREDIT') && form.companyCocNumber">
        <a-col :sm="12" :xs="24">
         <div class="sDash_form-action">
          <sdButton :disabled="disableInputs" class="btn-signin"
                    type="primary"> Opslaan
          </sdButton>
         </div>
        </a-col>
       </a-row>
      </a-form>
     </sdCards>
    </BasicFormWrapper>
   </div>
   <div class="user-info-form">
    <a-skeleton v-if="loadingRelation" active/>
    <BasicFormWrapper v-else class="mb-25">
     <sdCards title="Adres">
      <a-form :model="form" :rules="rules3" layout="vertical" @finish="UpdateRelation3">
       <a-row :gutter="30">
        <a-col :sm="9" :xs="24" class="mb-25">
         <a-form-item label="Postcode" name="postalCode" v-bind="validateInfos.postalCode">
          <a-input v-model:value="form.postalCode" :disabled="disableInputs"
                   placeholder=""/>
         </a-form-item>
        </a-col>
        <a-col :sm="6" :xs="24" class="mb-25">
         <a-form-item label="Huisnummer" name="houseNumber" v-bind="validateInfos.houseNumber">
          <a-input v-model:value="form.houseNumber" :disabled="disableInputs"
                   onkeydown="if(event.key==='.'||event.key===','){event.preventDefault();}"
                   onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                   placeholder=""
                   type="number"/>
         </a-form-item>
        </a-col>
        <a-col :sm="6" :xs="24" class="mb-25">
         <a-form-item label="Huisnummertoevoeging" name="houseNumberAddition">
          <a-input v-model:value="form.houseNumberAddition" :disabled="disableInputs"
                   placeholder=""/>
         </a-form-item>
        </a-col>
        <!--        <a-col :sm="3" :xs="24" class="mb-25">-->
        <!--         <a-form-item label="&nbsp;">-->
        <!--          <a-button :disabled="disableInputs" class="btn-primary" size="large" type="primary"-->
        <!--                    @click.prevent="SearchAddress">-->
        <!--           <sdFeatherIcons class="mr-1" size="16" type="search"/>-->
        <!--           <span>Zoeken</span>-->
        <!--          </a-button>-->
        <!--         </a-form-item>-->
        <!--        </a-col>-->
       </a-row>
       <a-row :gutter="30">
        <a-col :sm="10" :xs="24" class="mb-25">
         <a-form-item label="Straat" name="street" v-bind="validateInfos.street">
          <a-input v-model:value="form.street" :disabled="disableInputs"
                   placeholder=""/>
         </a-form-item>
        </a-col>
        <a-col :sm="7" :xs="24" class="mb-25">
         <a-form-item label="Plaats" name="city" v-bind="validateInfos.city">
          <a-input v-model:value="form.city" :disabled="disableInputs"
                   placeholder=""/>
         </a-form-item>
        </a-col>
        <a-col :sm="7" :xs="24" class="mb-25">
         <a-form-item label="Land" name="country" v-bind="validateInfos.country">
          <a-select v-model:value="form.country" :allowClear="true" :disabled="disableInputs"
                    class="sDash_fullwidth-select"
                    name="country"
                    size="large" @change="handleChange('COUNTRY',$event)">
           <a-select-option v-for="(item,index) in countries" :key="index" :value="item.value">
            {{ item.key }}
           </a-select-option>
          </a-select>
         </a-form-item>
        </a-col>
       </a-row>
       <a-row v-if="showCardSaveButton" :xs="12">
        <a-col :sm="12" :xs="24">
         <div class="sDash_form-action">
          <sdButton :disabled="disableInputs" class="btn-signin"
                    type="primary"> Opslaan
          </sdButton>
         </div>
        </a-col>
       </a-row>
      </a-form>
     </sdCards>
    </BasicFormWrapper>
   </div>
   <sdCards v-if="showOrders" title="Orders">
    <a-row :gutter="15">
     <a-col :md="24">
      <RelationOrderTable/>
     </a-col>
    </a-row>
   </sdCards>
   <sdCards v-if="showFooter">
    <a-row>
     <a-col :xs="24">
      <div class="sDash_form-action">
       <sdButton type="light" @click="$router.replace('/relation')">
        Terug
       </sdButton>
       <sdButton v-if="showGlobalSaveButton" :disabled="disableInputs" class="ml-4" type="primary"
                 @click.prevent="UpdateRelation">
        {{ type === 'ADD' ? 'Aanmaken' : 'Opslaan' }}
       </sdButton>
      </div>
     </a-col>
    </a-row>
   </sdCards>
  </RelationMain>
 </div>
</template>

<script>
import {computed, defineComponent, onMounted, reactive, ref} from 'vue';
import {useStore} from "vuex";
import {useRoute} from "vue-router";
import {companyLegalFormOptions, customerTypeOptions, genderOptions} from "../../utility/enums";
import moment from "moment";
import {AutoCompleteStyled} from "../autoComplete/style";
import {AutoCompleteWrapper, BasicFormWrapper, DatePickerWrapper, RelationMain, Upload} from "../../view/styled";
import VueTypes from "vue-types";
import {useForm} from "ant-design-vue/lib/form";
import {ibanValidator, phoneNumberValidator, postalCodeValidator} from "../../utility/validators";
import {useAbility} from "@casl/vue";
import RelationOrderTable from "@/components/relation/component/RelationOrderTable.vue";

export default defineComponent({
 name: 'RelationEdit',
 components: {
  BasicFormWrapper,
  RelationMain,
  Upload,
  AutoCompleteWrapper,
  AutoCompleteStyled,
  DatePickerWrapper,
  RelationOrderTable,
 },
 data() {
  return {
   dateFormat: 'DD-MM-YYYY',
   valueFormat: 'YYYY-MM-DD',
   cocCompanyName: null,
  }
 },
 props: {
  disableIsBusiness: VueTypes.bool.def(false),
  minAge: VueTypes.number,
  maxAge: VueTypes.number,
  showCardSaveButton: VueTypes.bool.def(true),
  showGlobalSaveButton: VueTypes.bool.def(false),
  type: VueTypes.oneOf(['EDIT', 'ADD', 'ORDEREDIT']).def('ADD'),
  showHeader: VueTypes.bool.def(true),
  showFooter: VueTypes.bool.def(true),
  relation: VueTypes.object,
  orderId: VueTypes.string.def(null),
  canUpdateRelation: VueTypes.bool.def(true),
  isOrderCreate: VueTypes.bool.def(false),
  enableBirthday: VueTypes.bool.def(true),
  showIBAN: VueTypes.bool.def(true),
  showOrders: VueTypes.bool.def(false),
 },
 methods: {
  submitExternal() {
   this.externalSubmitRelation();
  },
 },
 setup(props, {emit}) {
  const {state, dispatch} = useStore();
  const {params} = useRoute();
  const {can} = useAbility();
  let form = null;
  if (props.type !== 'ORDEREDIT') {
   form = reactive(computed(() => state.relation.relation));
  } else {
   form = reactive(computed(() => props.relation));
  }
  const errorRelation = computed(() => state.relation.errorRelation);
  const cocOptions = computed(() => state.searchCocNL.data);
  const loadingRelation = computed(() => state.relation.loadingRelation);
  const disableInputs = computed(() => !props.canUpdateRelation || (!can('update', 'relation') && !props.isOrderCreate));
  const companyInfoForm = ref();

  const rules1 = {
   gender: [
    {
     required: true,
     message: 'Dit veld is verplicht',
    },
   ],
   firstName: [
    {
     required: true,
     message: 'Dit veld is verplicht',
    },
    // {
    //  min: 2,
    //  message: 'Vul een volledige voornaam in.',
    // },
    {
     pattern: /^[A-Za-z]/i,
     message: 'Mag alleen alfanumerieke tekens.',
    },
   ],
   lastName: [
    {
     required: true,
     message: 'Dit veld is verplicht',
    },
   ],
   email: [
    {
     required: true,
     type: "email",
     message: "Vul een valide e-mailadres in",
    },
   ],
   phoneNumber: [
    {
     required: true,
     message: 'Dit veld is verplicht',
    },
    {
     validator: phoneNumberValidator,
    },
   ],
   birthDate: [
    {
     required: props.enableBirthday,
     message: 'Dit veld is verplicht',
    },
   ],
   bankAccountNumber: [
    {
     required: false,
     message: 'Dit veld is verplicht',
    },
    {
     validator: ibanValidator,
    },
   ],
   debtorName: [
    {
     required: false,
     message: 'Dit veld is verplicht',
    },
   ],
   companyLegalForm: [
    {
     required: false,
     message: 'Dit veld is verplicht',
    },
   ],
  };

  const rules3 = {
   postalCode: [
    {
     required: true,
     message: 'Dit veld is verplicht',
    },
    {
     validator: postalCodeValidator,
    },
   ],
   houseNumber: [
    {
     required: true,
     message: 'Dit veld is verplicht',
    },
   ],
   city: [
    {
     required: true,
     message: 'Dit veld is verplicht',
    },
   ],
   street: [
    {
     required: true,
     message: 'Dit veld is verplicht',
    },
   ],
   country: [
    {
     required: true,
     message: 'Dit veld is verplicht',
    },
   ],
  };

  const rulesSearchAddress = {
   postalCode: [
    {
     required: true,
     message: 'Dit veld is verplicht',
    },
    {
     validator: phoneNumberValidator,
    },
   ],
   houseNumber: [
    {
     required: true,
     message: 'Dit veld is verplicht',
    },
   ],
  };

  const {validate, validateInfos} = useForm(form, {...rules1, ...rules3});
  const UpdateRelation = () => {
   if (form?.value?.bankAccountNumber) {
    form.value.bankAccountNumber = form.value.bankAccountNumber.toUpperCase()
   }

   if (props.type === 'EDIT' || props.type === 'ORDEREDIT') {
    if (props.enableBirthday) {
     emit('submit', form);
    } else {
     form.value.birthDate = null;
     emit('submit', form);
    }
    return;
   }
   if (props.type === 'ADD') {
    validate()
     .then(() => {
      if (props.enableBirthday) {
       form.value.birthDate = moment(form.value.birthDate).format('YYYY-MM-DD');
      } else {
       form.value.birthDate = null;
      }
      dispatch('createRelation', form);
     })
     .catch(() => {
     });

   }
  };

  const externalSubmitRelation = () => {
   const companyInfoValidators = [companyInfoForm.value.validate()]
   Promise.all(companyInfoValidators).then(() => {
    validate()
     .then(() => {
      if (form?.value?.bankAccountNumber) {
       form.value.bankAccountNumber = form.value.bankAccountNumber.toUpperCase()
      }

      if (props.enableBirthday) {
       form.value.birthDate = moment(form.value.birthDate).format('YYYY-MM-DD');
      } else {
       form.value.birthDate = null;
      }
      emit('submit-external', form);
     })
   })
  }


  const UpdateRelation1 = () => {
   dispatch('patchRelation', {
    id: params.id,
    saveToRevision: form.value.saveToRevision,
    gender: form.value.gender,
    firstName: form.value.firstName,
    middleName: form.value.middleName ? form.value.middleName : '',
    lastName: form.value.lastName,
    email: form.value.email,
    phoneNumber: form.value.phoneNumber,
    birthDate: form.value.birthDate ? moment(form.value.birthDate).format('YYYY-MM-DD') : null,
    bankAccountNumber: form.value.bankAccountNumber,
    debtorName: form.value.debtorName,
    companyLegalForm: form.value.companyLegalForm,
    orderID: props.orderId,
    relationObjectType: props.type,
   });
  };

  const UpdateRelation2 = () => {
   const companyInfoValidators = [companyInfoForm.value.validate()]
   Promise.all(companyInfoValidators).then(() => {
    dispatch('patchRelation', {
     id: params.id,
     saveToRevision: form.value.saveToRevision,
     companyName: form.value.companyName,
     companyCocNumber: form.value.companyCocNumber,
     orderID: props.orderId,
     companyLegalForm: form.value.companyLegalForm,
     relationObjectType: props.type,
    });
   });
  };

  const UpdateRelation3 = () => {
   dispatch('patchRelation', {
    id: params.id,
    saveToRevision: form.value.saveToRevision,
    city: form.value.city,
    country: form.value.country,
    houseNumber: form.value.houseNumber,
    houseNumberAddition: form.value.houseNumberAddition,
    postalCode: form.value.postalCode,
    street: form.value.street,
    orderID: props.orderId,
    relationObjectType: props.type,
   });
  };

  const onSearchCoc = (searchText) => {
   dispatch('searchCocNL', searchText);
  };

  const onCocSelect = (data) => {
   form.value.companyName = data.companyName;
   form.value.companyCocNumber = data.companyCocNumber;
   form.value.city = data.city;
   form.value.country = data.country;
   form.value.houseNumber = data.houseNumber;
   form.value.houseNumberAddition = data.houseNumberAddition;
   form.value.postalCode = data.postalCode;
   form.value.street = data.street;
  };

  const setAddress = (data) => {
   form.city = data.city;
   form.street = data.street;
   form.country = 'NL';
  }

  const SearchAddress = () => {
   validate(["postalCode", "houseNumber"])
    .then(() => {
     dispatch('searchAddress', {
      values: {
       postalCode: form.postalCode,
       houseNumber: form.houseNumber,
       houseNumberAddition: form.houseNumberAddition,
       country: 'NL',
      },
      setAddress,
     });
    })
  };

  const handleChange = (type, event) => {
   if (type === 'CUSTOMERTYPE') {
    form.type = event.target.value;
    if (event === 'BUSINESS') {
     this.cocCompanyName = null;
     form.companyName = null;
     form.companyCocNumber = null;
    }
    return;
   }
   if (type === 'GENDER') {
    form.gender = event;
    return;
   }
   if (type === 'COUNTRY') {
    form.country = event;

   }
  };

  const disabledDate = (current) => {
   const startDate = moment().subtract(props.maxAge > 0 ? props.maxAge : 100, 'years')
   const endDate = moment().subtract(props.minAge > 0 ? props.minAge : 18, 'years')
   return !current.isBetween(startDate, endDate);
  };

  onMounted(() => {
   if (props.type === 'EDIT') {
    dispatch('getRelation', params.id);
    dispatch('getRelationOrders', params.id);
   }
  });

  return {
   form,
   rules1,
   rules3,
   errorRelation,
   UpdateRelation,
   UpdateRelation1,
   UpdateRelation2,
   UpdateRelation3,
   onSearchCoc,
   cocOptions,
   onCocSelect,
   genderOptions,
   rulesSearchAddress,
   SearchAddress,
   loadingRelation,
   customerTypeOptions,
   handleChange,
   disabledDate,
   validateInfos,
   externalSubmitRelation,
   moment,
   disableInputs,
   companyInfoForm,
   companyLegalFormOptions,
  };
 },
});
</script>
